<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Scenario detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2" v-if="item">
      <b-card class="my-1">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>

      <!-- <h3 class="h2">Useful vocab</h3>
      <word-list :word-ids="item.useful_vocab" />

      <h3 class="h2 mt-3">Useful expression</h3>
      <word-list :word-ids="item.useful_expression" /> -->
      <h3 class="h2 mt-3">Useful vocabs</h3>
      <dynamic-zone ref="useful_vocab" item-collapse="last"
        :items="item.vocabs"
        title_field="title.translations[0]"
        :default-data="{
          title: {
            type: 'translatable-text',
            value: null,
            translations: [],
          },
          words: [],
        }"
      >
        <template v-slot="{ item: vocab_item }">
          <form-generator :model="vocab_item" :schema="[{
            cols: 12, fields: [
              {label: 'Title', field: 'title', input_type: 'ui-component' }
            ],
          }]" />
          <word-list :word-ids="vocab_item.words" class="word_list" />
        </template>
      </dynamic-zone>


      <h3 class="h2 mt-3">AI config</h3>
      <span>Avaiable params: <code>`[user_level]`, `[username_code]`</code></span>
      <b-card class="mt-1">
        <form-generator :model="item.openai_config" :schema="ai_config_schema" />

        <div v-if="item.openai_config.auto_chat" class="border p-1">
          <h4 class="h4">Default messages</h4>
          <dynamic-zone ref="default_messages" item-collapse="last"
            :items="item.openai_default_messages"
            title_field="content"
            :default-data="{
              content: '',
            }"
          >
            <template v-slot="{ item: message_item }">
              <form-generator :model="message_item" :schema="[{
                cols: 12, fields: [
                  { label: 'Content', field: 'content', input_type: 'textarea' }
                ],
              }]" />
            </template>
          </dynamic-zone>
        </div>
      </b-card>

    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import { buildSelectOptions } from '@core/utils/index';
import { TUTOR_VOICES } from '../constant';
import service from '../service';
import tagService from '../../tag/service';
import WordList from '@/modules/arena_v2/milestone/views/_components/WordList.vue';

const voice_options = buildSelectOptions(TUTOR_VOICES);

const tag_options = [];

const item_form_schema = [
  {
    cols: 6,
    fields: [
      { label: 'Title', field: 'title', input_type: 'text', validate: { required: true }, },
      { label: 'Career field', field: 'career_field', input_type: 'text', validate: { required: true } },
      { label: 'Position', field: 'position', input_type: 'text', validate: { required: true } },
    ]
  },
  {
    cols: 3,
    fields: [
      { label: 'Image', field: 'image', input_type: 'image' },
    ]
  },
  {
    cols: 3,
    fields: [
      { label: 'Background', field: 'background', input_type: 'image' },
    ]
  },
  {
    cols: 12,
    fields: [
      { label: 'Tutor voice', field: 'tutor_voice', input_type: 'select', options: voice_options },
      { label: 'Tutor role', field: 'tutor_role', input_type: 'ui-component' },
      { label: 'Tutor role text', field: 'tutor_role_text', input_type: 'ui-component' },
      // { label: 'Tutor role info', field: 'tutor_role_info', input_type: 'ui-component' },
      { label: 'Tutor tags', field: 'tutor_tags', input_type: 'multiselect', options: tag_options },
      { label: 'Scenario info', field: 'scenario_info', input_type: 'ui-component' },
      { label: 'Scenario objective', field: 'scenario_objective', input_type: 'ui-component' },
    ]
  },
];
const ai_config_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Prompt', field: 'prompt', input_type: 'textarea' },
      { label: 'Username code', field: 'username_code', input_type: 'text', validate: { required: true } },
      { label: 'Endchat placeholder', field: 'endchat_placeholder', input_type: 'textarea' },
      { label: 'Auto chat', field: 'auto_chat', input_type: 'switch' },
    ]
  },
];

export default {
  components: {
    WordList,
  },
  data(){
    return{
      item_form_schema, ai_config_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Scenario list',
          to: { name: 'ai_scenario-list' },
        },
        {
          text: `${this.item ? this.item.title : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getItem();
    this.getTags();
  },
  methods:{
    // level
    async getItem(){
      this.item = await service.get({id: this.itemId});
    },
    async getTags(){
      let result = await tagService.getList({});
      let list = result?.data.data?.list || [];
      let options = list.map(item => {
        return {
          text: item.key,
          value: item.key,
        };
      });
      tag_options.length = 0;
      tag_options.push(...options);
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "ai_scenario-list" });
    },
  }
}
</script>

<style>
.word_list .card {
  border: 1px solid #eee;
  margin-top: 12px;
}
</style>
