var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[(_vm.item)?_c('page-breadcrumb',{staticClass:"mb-2",attrs:{"title":"Scenario detail","items":_vm.breadcrumbItems}}):_vm._e(),(_vm.item)?_c('section',{staticClass:"mb-2"},[_c('b-card',{staticClass:"my-1"},[_c('form-generator',{attrs:{"model":_vm.item,"schema":_vm.item_form_schema}})],1),_c('h3',{staticClass:"h2 mt-3"},[_vm._v("Useful vocabs")]),_c('dynamic-zone',{ref:"useful_vocab",attrs:{"item-collapse":"last","items":_vm.item.vocabs,"title_field":"title.translations[0]","default-data":{
        title: {
          type: 'translatable-text',
          value: null,
          translations: [],
        },
        words: [],
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var vocab_item = ref.item;
return [_c('form-generator',{attrs:{"model":vocab_item,"schema":[{
          cols: 12, fields: [
            {label: 'Title', field: 'title', input_type: 'ui-component' }
          ],
        }]}}),_c('word-list',{staticClass:"word_list",attrs:{"word-ids":vocab_item.words}})]}}],null,false,1081555976)}),_c('h3',{staticClass:"h2 mt-3"},[_vm._v("AI config")]),_vm._m(0),_c('b-card',{staticClass:"mt-1"},[_c('form-generator',{attrs:{"model":_vm.item.openai_config,"schema":_vm.ai_config_schema}}),(_vm.item.openai_config.auto_chat)?_c('div',{staticClass:"border p-1"},[_c('h4',{staticClass:"h4"},[_vm._v("Default messages")]),_c('dynamic-zone',{ref:"default_messages",attrs:{"item-collapse":"last","items":_vm.item.openai_default_messages,"title_field":"content","default-data":{
            content: '',
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var message_item = ref.item;
return [_c('form-generator',{attrs:{"model":message_item,"schema":[{
              cols: 12, fields: [
                { label: 'Content', field: 'content', input_type: 'textarea' }
              ],
            }]}})]}}],null,false,2316382830)})],1):_vm._e()],1)],1):_vm._e(),_c('action-buttons',{attrs:{"update-button":"","back-button":"","delete-button":"","is-updating":_vm.isUpdating,"is-deleting":_vm.isDeleting},on:{"update":_vm.update,"delete":_vm.deleteItem}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Avaiable params: "),_c('code',[_vm._v("`[user_level]`, `[username_code]`")])])}]

export { render, staticRenderFns }